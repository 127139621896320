import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import deviceStoreModule from './deviceStoreModule'
if (!store.hasModule('device')) store.registerModule('device', deviceStoreModule)
import { swal, Toast } from '@core/utils/other'

export default function useDevice(props, emit) {
  const loading = ref(false)

  // Table basic
  let tableColumns = [ //key: for table, field for DB
    { key: 'name', sortable: true },    
    { key: 'currentGuard', label:'Current user', sortable: true },
    { key: 'number', label:'Hardware ID',sortable: true },
    { key: 'tags', sortable: true},
    { key: 'actions' },
  ]
  if (props && props.selectable === true) tableColumns.unshift({ key: 'selected', label:''})
  const perPage = ref(25)
  const total = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50, 100, 200]
  const searchQuery = ref('')
  const sortBy = ref('_id')
  const isSortDirDesc = ref(true)

  // Table filters
  const filterInputs = ref({})
  const filterSelects = ref({})
  const filterTable = ref({disabled: {$ne: true}})
  const filterTableOptions =ref([
    {title:'valid', value: {disabled: {$ne: true}}},
    {title:'all', value:null}
  ])

  // Table selection
  const selectedData = ref([])
  const onRowSelected = (val)=>{selectedData.value = val}
  const selectAllCheckbox =ref(null)
  const refDeviceListTable = ref(null) // Table obj
  const selectAll = function (val){
    if (val) {
      refDeviceListTable.value.selectAllRows()
    } else {
      refDeviceListTable.value.clearSelected()
    }
  }

  // 延迟
  let awaitingStartTime = 0;
  const awaitAction = (func, duration=750) => {
    let selfStartTime = + new Date()
    awaitingStartTime = selfStartTime;
    setTimeout(()=>{
      if (awaitingStartTime === selfStartTime) func()
    }, duration)
  }

  const dataMeta = computed(() => {
    const localItemsCount = refDeviceListTable.value ? refDeviceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: total.value,
    }
  })

  const refetchData = () => {
    refDeviceListTable.value.refresh()
  }

  watch([currentPage, perPage, filterSelects, filterTable], () => { refetchData() }, {deep: true})
  watch([searchQuery, filterInputs], () => { awaitAction(refetchData) }, {deep: true})
  watch(selectedData, ()=>{
    if (!selectedData.value.length) selectAllCheckbox.value = false
    emit('update:selected-data', selectedData.value)
  })

  // 获取列表
  const fetchDevices = (ctx, callback) => {
    selectAllCheckbox.value = false
    let storeObj = {
      fields: searchQuery.value?'name,tags':'',
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sort: isSortDirDesc.value? ('-' + sortBy.value) : sortBy.value,
    }

    // Head filters
    let filtersArry = []
    let filters = {...filterInputs.value, ...filterSelects.value}
    for (let key in filters){
      if (key && filters[key]) {
        filtersArry.push({ [key]: filters[key]})
      }
    }
    if (filterTable.value) filtersArry.push(filterTable.value)
    storeObj.filters = JSON.stringify(filtersArry)
    
    store.dispatch('device/fetchDevices', storeObj)
    .then(response => {
      callback(response.devices)
      total.value = response.total
    }).catch(error => {
      Toast.fire({icon:'error', title:error.message})
    })
  }

/// Sidebar
  // 表格 validation 重制
  const nameRef = ref(null)
  const numberRef = ref(null)
  const resetTableInput = ()=>{
    if(nameRef.value) nameRef.value.resetValidation()
    if(numberRef.value) numberRef.value.resetValidation()
  }

  const isSidebarActive = ref(false);
  watch(()=>store.state.device.isSidebarActive, val=>{ 
    isSidebarActive.value = val;
    if(!val){// Sidebar 关闭后重置
      store.commit('device/RESET_DEVICE')
      resetTableInput()
    }
  })

  const deviceId = ref('');
  watch(()=>store.state.device.deviceId, (val)=> deviceId.value = val)

  const deviceData = ref({});
  watch(()=>store.state.device.deviceData, (val)=>deviceData.value = val, {deep: true})

  const deviceFiles = ref([]);
  watch(()=>store.state.device.deviceFiles, (val)=>deviceFiles.value = val, {deep: true})

 
  // 获取 Device 数据
  const fetchDevice = function(id) {
    if (!id) return
    store.dispatch('device/fetchDevice', id).then(({device})=>{
      deviceData.value = device
    }).catch(error => {
      Toast.fire({icon:'error', title:error.message})
      //store.commit('device/RESET_DEVICE')
      store.commit('device/TOGGLE_SIDEBAR', false)
    })
  }

  // 更新 Device 数据  
  const updateDevice = function(){
    loading.value = true
    store.dispatch('device/updateDevice', {
      id: deviceId.value,
      device: deviceData.value
    }).then(()=>{
      Toast.fire({ icon:'success', title:'Success' })
      store.commit('device/SET_IF_DEVICE_LIST_UPDTAE', true) // Set to update list
      store.commit('device/TOGGLE_SIDEBAR', false) // Close sidebar
      loading.value=false
      resetTableInput()
    }).catch(error=>{
      Toast.fire({icon:'error', title: error.message})
      loading.value=false
    })
  }

  // Device 失效
  const disableDevice = function(id){
    swal.fire({
      title: 'Confirm to disable?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, disable!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
      heightAuto: false
    }).then(v => {
      if(!v.value) return
      store.dispatch('device/disableDevice', id).then(() => {
        store.commit('device/SET_IF_DEVICE_LIST_UPDTAE', true)
        swal.fire({
          icon: 'success',
          title: 'Disabled!',
          customClass: { confirmButton: 'btn btn-success' },
          timerProgressBar: true,
          timer: 1000,
          heightAuto: false
        }).catch(swal.noop)
        store.dispatch("count/updateCounts")
      }).catch(e=>{
        swal.fire({
          icon: 'error',
          title: 'Failed to delete!',
          text: e.message,
          customClass: { confirmButton: 'btn btn-success' },
          heightAuto: false
        })
      })
    })
  }
  

  // File Upload
  const successUpload = function(e){
    if (!deviceId.value){ //只有 Add new 才上传
      const uploadeObj = JSON.parse(e.target.response).files;
      if (uploadeObj && uploadeObj.length>0) deviceData.value.files = deviceData.value.files.concat(uploadeObj) // 上传的图片信息
    }
    Toast.fire({icon:'success', title:'Success'})
  }

  const failUpload = function(e){
    console.log(e);
    Toast.fire({icon:'error', title: 'upload failed'})
  }

  const deleteUpload = function(e){
    store.dispatch('device/removeFile', {
      id: deviceData.value._id || null,
      fileName: e.newFileName
    }).then(() => {
      if(!deviceData.value._id){ //如果是Add device
        // 移除照片信息
        for (const [i, file] of deviceData.value.files.entries()) {
          if (file.name === e.newFileName) delete deviceData.value.files.splice(i,1)
        }
      }
      Toast.fire({icon:'success', title: 'File deleted'})
    }).catch(e=>{
      Toast.fire({icon:'error', title: JSON.parse(e).message})
    })
  }


  return {
    loading, Toast,
    fetchDevices,
    disableDevice,
    nameRef, numberRef,

    // Table
    tableColumns,
    perPage,
    currentPage,
    total,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDeviceListTable,
    refetchData,

    onRowSelected,
    selectedData,
    selectAllCheckbox,
    selectAll,

    // Sidebar
    isSidebarActive,
    deviceId, deviceData, deviceFiles,
    fetchDevice,
    updateDevice,

    // Filters
    filterInputs,
    filterSelects,
    filterTable, filterTableOptions,

    // File upload functions
    successUpload,
    failUpload,
    deleteUpload
  }
}
