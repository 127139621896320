import axios from '@axios'

export default {
  namespaced: true,
  state: {
    deviceListToUpdate: false,
    isSidebarActive: false,
    deviceId: '',
    deviceData: {files:[]},
    deviceFiles: []
  },
  getters: {},
  mutations: {
    SET_IF_DEVICE_LIST_UPDTAE(state, val){
      state.deviceListToUpdate = val
    },
    TOGGLE_SIDEBAR(state, val){ // Open/close
      state.isSidebarActive = val
    },
    RESET_DEVICE(state, val){
      state.deviceId = '',
      state.deviceData = {files:[]},
      state.deviceFiles = []
    },
    SET_DEVICE_ID(state, val){
      state.deviceId = val
    },
    SET_DEVICE_DATA(state, data){
      state.deviceData = data
      state.deviceFiles = data.files
    }
  },
  actions: {
    fetchDevices({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/device', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('SET_IF_DEVICE_LIST_UPDTAE', false)
          })
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    fetchDevice({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/device/${id}`)
          .then(response => {
            commit('SET_DEVICE_DATA', response.data.device)
            resolve(response.data)
          }).catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    updateDevice(ctx, {id, device}) {
      return new Promise((resolve, reject) => {
        axios.post(`/device/${id||''}`, { ...device })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    disableDevice(ctx, id){
      return new Promise((resolve, reject) => {
        axios.delete(`/device/${id}`)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    removeFile(ctx, {id, fileName}){
      let url =''
      if (id) { url = `/device/${id}/file/${fileName.replaceAll('/','%2F')}` } else { url = `/device/file/${fileName}` }
      return new Promise((resolve, reject) => {
        axios.delete(url)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
  },
}
